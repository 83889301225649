import { AnchorHTMLAttributes } from "react";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { Product } from "@ludens-reklame/rubics-v3-sdk/product/types";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { RichText } from "../../internal-components/richText/RichText.js";
import { arrow } from "../../icons/svg.js";
import { getLinkProps } from "../../client-utils/getLinkProps.js";

interface BaseCard {
  title?: string;
  link?: Link;
}

interface ProductCard extends BaseCard {
  product?: Product;
}

interface Props {
  text?: string;
  useFader?: boolean;
  cards?: (ProductCard | BaseCard)[];
}

const Paywall: React.FC<Props> = ({ text, useFader, cards = [] }) => {
  const store = useStore();

  if (store.context.adminMode) {
    return null;
  }

  return (
    <div className="paywall hs vs-xl mw">
      {useFader && <div className="fader" />}
      <div className="content">
        {text && <RichText className="mw-small" html={text} />}
        {cards.length > 0 && (
          <div className="card-list">
            {cards.map((card, index) => {
              let linkProps: AnchorHTMLAttributes<HTMLAnchorElement> = {};
              let hasLink: boolean = false;

              if (card.link) {
                linkProps = getLinkProps(card.link);
                hasLink = true;
              } else if ("product" in card && card.product) {
                linkProps = { href: card.product.url };
                hasLink = true;
              }

              return (
                <div key={index} className="item">
                  {card.title && <p className="h6 title">{card.title}</p>}
                  {"product" in card && card.product && (
                    <p className="h6">{card.product.priceText}</p>
                  )}
                  {hasLink && (
                    <a className="button tertiary" {...linkProps}>
                      Les mer {arrow}
                    </a>
                  )}
                </div>
              );
            })}
          </div>
        )}
        <div className="login">
          <h6>Allerede abonnent?</h6>
          <a
            className="button"
            href={`/logg-inn?redirect=${store.context.url.full}`}
          >
            Logg inn her {arrow}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Paywall;
